export default Object.freeze({
    LAST_MODIFIED: "Last Modified",
    APPLY: "Apply",
    RESET_DEFAULT: "Reset to Default",
    RESET_CORPORATE: "Reset to Corporate",
    TIMELINE_HEADING:"Hover over the automation stages to edit.",
    PROPERTY_LEVEL_PAUSE_TOOLTIP_TEXT: "Delinquency has been paused at the property level. To enable the button, resume delinquency at the property level.",
    NO_STAGE_CREATED: 'There are no stages created yet',
    DELINQUENCY_AUTOMATION: "Delinquency Automation",
    RESET_WARNING: "No automation reset possible",
    STATUSES: {
        TENANT_PROCESS: "tenant_process",
        CORPORATE_MODIFIED: "corporate_modified",
        PROPERTY_MODIFIED: "property_modified"
    },
    ICONS: {
        tenant_process: {
            name: "Tenant<sup>TM</sup> Process",
            slug: "tenant_process",
            icon: "mdi-custom-tenant-logo",
            icon_color: "#38AFC6"
        },
        corporate_modified: {
            name: "Corporate Modified",
            slug: "corporate_modified",
            icon: "mdi-check-circle",
            icon_color: "#02AD0F"
        },
        property_modified: {
            name: "Property Modified",
            slug: "property_modified",
            icon: "mdi-check-circle",
            icon_color: "#FFD600"
        }
    },
    MENU_TABS : {
        CORPORATE: "corporate",
        PROPERTY: "property",
    },
    APPLIED_PROPERTIES_TEXT: {
        CORPORATE: "Applies to",
        PROPERTY: "Shared Between",
        TOOLTIP_HEADER: {
            CORPORATE: "Applies to",
            PROPERTY: "Other properties using this same workflow"
        }
    },
    RESET_SUCCESS_MESSAGE: {
        CORPORATE_MODIFIED: "Automation Reset to Corporate Workflow Successfully",
        TENANT_PROCESS: "Automation Reset to Default Workflow Successfully"
    }

})